import request from '../utils/request'
/*
* ============新增客服
* @params{
*
* addAccountVo:{
*      accountname: string      账号
*      auditlimit:string          银行名称
*      headimg:string              头像
*      level:string                等级
*      nickname:init                昵称
*      accountstatus:init              账号状态
*     }
* }
* */

/*
*==============客服列表
* @params{
* searchAccountVo:{       查询条件
*   accountname:string    客服名称
* }
* searchByPageVo:{
*    page:init
*    rows:init
* }
* */
//订单列表
export function searchByCondition(data) {
    return request({
        url: '/platform/food/order/data',
        method: 'get',
        data
    })
}
//订单列表
export function searchByConditionCopy(data) {
    return request({
        url: '/platform/food/order/data-copy',
        method: 'get',
        data
    })
}
//订单导出
export function dataExportDo(data) {
    return request({
        url: '/platform/food/order/dataExportDo',
        method: 'post',
        responseType: 'blob',
        isReturn:true,
        data
    })
}
//确认收款
export function docollect(data) {
    return request({
        url: '/platform/food/order/docollect/'+data,
        method: 'get',
    })
}
//地址列表
export function addressData(data) {
    return request({
        url: '/platform/food/address/data',
        method: 'get',
        data
    })
}
//确认订单已完成
export function dofinish(id) {
    return request({
        url: `platform/food/order/dofinish/${id}`,
        method: 'post',
    })
}
//确认订单已完成
export function dofinishs(data) {
    return request({
        url: `platform/food/order/dofinish`,
        method: 'post',
        data
    })
}
